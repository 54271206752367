/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {getScrollTriggers, setScrollTrigger} from "utils/scrolltriggers";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    strong: "strong",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {ResourcePanel, Pin, InfoNugget, Headline, Obl, CareInstructions, CareItem} = _components;
  if (!CareInstructions) _missingMdxReference("CareInstructions", true);
  if (!CareItem) _missingMdxReference("CareItem", true);
  if (!Headline) _missingMdxReference("Headline", true);
  if (!InfoNugget) _missingMdxReference("InfoNugget", true);
  if (!Obl) _missingMdxReference("Obl", true);
  if (!Pin) _missingMdxReference("Pin", true);
  if (!ResourcePanel) _missingMdxReference("ResourcePanel", true);
  return React.createElement(React.Fragment, null, React.createElement(ResourcePanel, {
    id: "figure01",
    image: "figure01",
    imageAlt: "lorem ipsum",
    roundTopCorners: true,
    slug: props.data.mdx.frontmatter.slug,
    getTriggers: getScrollTriggers
  }, React.createElement(Pin, {
    id: "figure01",
    type: "start",
    height: 200,
    setTrigger: setScrollTrigger
  }), React.createElement(InfoNugget, null, React.createElement(Headline, {
    level: 2
  }, "No!"), React.createElement(_components.p, null, "Resist the urge and leave your skin alone. Popping pimples can backfire, and cause scars, hyperpigmentation and infection.")), React.createElement(InfoNugget, null, React.createElement(Headline, {
    level: 2
  }, "Cause"), React.createElement(_components.p, null, "Pimples form when the hair follicle gets clogged with sebum (oil), dead cells, and bacteria."), React.createElement(_components.p, null, "This leads to inflammation and swelling of the pore and the gland that produces the sebum."), React.createElement(_components.p, null, "Pus forms in the area, leading to a pimple.")), React.createElement(InfoNugget, null, React.createElement(_components.p, null, "It can be tempting to pop a pimple, but trying to pop one can make the breakout worse or lead to ", React.createElement(_components.strong, null, "scarring"), " and ", React.createElement(_components.strong, null, "hyperpigmentation"), ".")), React.createElement(Pin, {
    id: "figure01",
    type: "end",
    height: 200,
    setTrigger: setScrollTrigger
  })), "\n", React.createElement(ResourcePanel, {
    id: "figure02",
    image: "figure02",
    imageAlt: "lorem ipsum",
    slug: props.data.mdx.frontmatter.slug,
    getTriggers: getScrollTriggers,
    slug: props.data.mdx.frontmatter.slug
  }, React.createElement(Pin, {
    id: "figure02",
    type: "start",
    height: 200,
    setTrigger: setScrollTrigger
  }), React.createElement(InfoNugget, null, React.createElement(_components.p, null, "Though a little bit of pus comes out of the pimple, more of it is pushed deeper into the skin."), React.createElement(_components.p, null, React.createElement(_components.strong, null, "This can make the pimple worse or cause more pimples"), "."), React.createElement(_components.p, null, "It can also lead to ", React.createElement(_components.strong, null, "hyperpigmentation"), " once the swelling goes away.")), React.createElement(Pin, {
    id: "figure02",
    type: "end",
    setTrigger: setScrollTrigger
  })), "\n", React.createElement(ResourcePanel, {
    id: "figure03",
    image: "figure03",
    imageAlt: "lorem ipsum",
    slug: props.data.mdx.frontmatter.slug,
    getTriggers: getScrollTriggers
  }, React.createElement(Pin, {
    id: "figure03",
    type: "start",
    height: 400,
    setTrigger: setScrollTrigger
  }), React.createElement(InfoNugget, null, React.createElement(_components.p, null, "Though a pimple can seem like a small bump on the surface of the skin, ", React.createElement(_components.strong, null, "most of it is below the surface in the hair follicle"), ".")), React.createElement(Pin, {
    id: "figure03",
    type: "end",
    height: 400,
    setTrigger: setScrollTrigger
  })), "\n", React.createElement(ResourcePanel, {
    id: "video01",
    video: "gcRNPrlO33o",
    slug: props.data.mdx.frontmatter.slug,
    getTriggers: getScrollTriggers
  }, React.createElement(Pin, {
    id: "video01",
    type: "start",
    height: 600,
    setTrigger: setScrollTrigger
  }), React.createElement(InfoNugget, null, React.createElement(_components.p, null, "Hear Dr. Chesahna Kindred of the ", React.createElement(Obl, {
    to: "https://kindredhairandskin.com/",
    title: "Dr. Kindred's Practice"
  }, "Kindred Hair and Skin Center"), " share more on how to best treat pimples.")), React.createElement(Pin, {
    id: "video01",
    type: "end",
    height: 200,
    setTrigger: setScrollTrigger
  })), "\n", React.createElement(CareInstructions, null, React.createElement(Headline, {
    level: 3
  }, "Treatment"), React.createElement(_components.p, null, "Many of the ways you prevent pimples can also help you manage them. For instance, washing your face with mild soap in the morning and at night, limiting sun exposure, drinking lots of water, avoiding touching your face, eating right, reducing stress, and not popping pimples may help contain them and reduce how long they stay around."), React.createElement(_components.p, null, "If you have bad acne despite taking steps to prevent it, you may need a prescription-strength treatment such as:"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(CareItem, {
    icon: "alert"
  }, React.createElement(Obl, {
    to: "https://amzn.to/3goiRGe",
    title: "Amazon Affiliate Link"
  }, "topical retinoids (derived from vitamin A)"), " to help prevent clogged pores"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(CareItem, {
    icon: "alert"
  }, React.createElement(Obl, {
    to: "https://amzn.to/3EwiL7s",
    title: "Amazon Affiliate Link"
  }, "oral contraceptives"), " or ", React.createElement(Obl, {
    to: "https://amzn.to/3GFBHDs",
    title: "Amazon Affiliate Link"
  }, "antiandrogen"), " agents to reduce hormones that increase sebum production"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(CareItem, {
    icon: "alert"
  }, "oral isotretinoin (", React.createElement(Obl, {
    to: "https://www.acne.org/accutane.html",
    title: "more info on Accutane"
  }, "Accutane"), "), a retinoid that helps prevent clogged pores, and reduces sebum production, inflammation, and skin bacteria"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(CareItem, {
    icon: "alert"
  }, "Prescription-strength treatments may cause serious side effects. Your dermatologist can help you weigh the pros and cons and determine which treatment is right for you."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(CareItem, {
    icon: "alert"
  }, "For more info - ", React.createElement(Obl, {
    to: "https://www.healthline.com/health/how-to-prevent-pimples#limit-sun",
    title: "Healthline info on how to prevent pimples"
  }, "https://www.healthline.com/health/how-to-prevent-pimples#limit-sun")), "\n"), "\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
